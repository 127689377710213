import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navigation from "./navigation"

// assets
import Logo from "../images/prasthana-logo.svg"
import BurgerMenu from "../images/burger-menu.svg"
import Close from "../images/close.svg";

const Header = ({toggleMenuState, menuState}) => {
  return (  
    <header className="site-header">
      <Link className="site-logo" to="/">
        <Logo />
      </Link>
      { menuState ? 
        <button onClick={() => toggleMenuState()}>
          <Close />
        </button> :
        <button onClick={() => toggleMenuState()}>
          <BurgerMenu />
        </button> }
      <Navigation menuState={menuState} />
    </header>
  )
} 

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
export { Logo }
