import React from 'react';
import { Link } from 'gatsby';

const Navigation = ({menuState}) => {
  return (
    <nav className={`navigation${menuState ? ' navigation--is-open' : ''}`}>
      <ul>
        <li>
          <Link activeClassName="is-active" to="/">Home</Link>
        </li>
        <li>
          <Link activeClassName="is-active" to="/about-us">About Us</Link>
        </li>
        <li>
          <Link activeClassName="is-active" to="/careers">Careers</Link>
        </li>
        <li>
          <Link activeClassName="is-active" to="/contact-us">Contact Us</Link>
        </li>
      </ul>
    </nav>    
  );
}

export default Navigation;
